<template>
  <div class="clients-list-container">
    <alert v-if="OCF21BSentInvoicesLoading" class="shadow-card" />
    <div
      class="card shadow-card mobile-tablet-no-card"
      v-if="!OCF21BSentInvoicesLoading"
    >
      <div class="card-body">
        <div class="text-dark col-12 col-md-auto mb-3 mb-md-0 p-0">
          <span>
            <i
              class="fas fa-spin fa-circle-notch"
              v-if="OCF21BSentInvoicesLoading"
            ></i>
            <b v-else>
              {{ getFilteredInvoices.length }}
              Invoice{{
                OCF21BSentInvoicesLoading || getFilteredInvoices.length != 1
                  ? "s"
                  : ""
              }}
            </b>
          </span>
        </div>
        <div class="form-group mb-0">
          <div class="criteria-dropdown">
            <select-control
              v-model="searchCriteria"
              :options="searchCriteriaOptions"
              labelClass="col-4"
              wrapClass="align-items-center"
            >
              Search Criteria:
            </select-control>
          </div>
          <div class="search-input">
            <input
              type="search"
              class="form-control pr-5"
              v-model.trim="searchContent"
              placeholder="Search by Selected Criteria..."
            />
            <i class="fa fa-search search-icon text-primary"></i>
            <i
              v-if="searchContent"
              class="fas fa-times cancel-icon"
              @click="searchContent = ''"
            ></i>
          </div>
        </div>
      </div>
      <div class="table-container p-1 p-lg-0" v-if="getFilteredInvoices.length">
        <table class="table table-striped mb-0 responsive-mobile-tablet-table">
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Invoice Date</th>
              <th>Affiliated Plan Number</th>
              <th>File Name</th>
              <th>Invoice Number</th>
              <th>Invoice Amount</th>
              <th>HCAI Status</th>
              <th>Approved Amount</th>
              <th>Plan Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="invoice in getFilteredInvoices"
              :key="invoice.invoice_number"
            >
              <td>
                <b class="mobile-tablet-only mr-2">First Name:</b>
                {{ invoice.first_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Last Name:</b>
                {{ invoice.last_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Invoice Date:</b>
                {{ formattedDate(invoice.invoice_date) }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Affiliated Plan Number:</b>
                {{ invoice.affiliated_plan_number }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">File Name:</b>
                {{ invoice.file_name }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Invoice Number:</b>
                {{ invoice.invoice_number }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Invoice Amount:</b>
                {{
                  parseFloat(invoice.invoice_amount)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">HCAI Status:</b>
                {{ invoice.HCAI_status }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Approved Amount:</b>
                {{
                  parseFloat(invoice.approved_amount)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
              <td>
                <b class="mobile-tablet-only mr-2">Plan Balance:</b>
                {{
                  parseFloat(invoice.plan_balance)
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body py-0" v-if="!getFilteredInvoices.length">
        <alert :hideLoader="true"> No Invoices </alert>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import * as dayjs from "dayjs";

export default {
  name: "OCF21BSentInvoices",
  data() {
    return {
      searchContent: "",
      searchCriteria: "first_name+last_name",
      searchCriteriaOptions: [
        { value: "first_name+last_name", label: "First and Last Name" },
        { value: "affiliated_plan_number", label: "Affiliated Plan Number" },
        { value: "file_name", label: "File Name" },
        { value: "invoice_number", label: "Invoice Number" },
      ],
    };
  },
  computed: {
    ...mapState({
      OCF21BSentInvoices: (state) => state.HCAI.OCF21BSentInvoices.data,
      OCF21BSentInvoicesLoading: (state) =>
        state.HCAI.OCF21BSentInvoices.loading,
    }),
    getFilteredInvoices: function () {
      const vm = this;
      if (this.searchContent) {
        const regex = new RegExp(`.*${this.searchContent}.*`, "i");
        return this.OCF21BSentInvoices.filter((invoice) => {
          const searchCriteria = vm.searchCriteria.split("+");
          let search = ``;
          searchCriteria.forEach((_searchCriteria) => {
            search = `${search} ${invoice[_searchCriteria]}`;
          });
          search = search.trim();
          return regex.test(search);
        });
      }
      return this.OCF21BSentInvoices;
    },
  },
  mounted() {
    this.getOCF21BSentInvoices();
  },
  methods: {
    ...mapActions({
      getOCF21BSentInvoices: "HCAI/getOCF21BSentInvoices",
    }),
    formattedDate: function (dateToformat) {
      if (dateToformat && dateToformat != "") {
        return dayjs(dateToformat).format("YYYY-MM-DD");
      } else return "";
    },
  },
};
</script>
<style scoped lang="scss">
.count-alert {
  border: 0;
  background-color: transparent;
  display: inline-block;
}
.col-12 > div {
  width: 100%;
}
.cancel-icon,
.search-icon {
  z-index: 99;
  position: absolute;
  right: 40px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #dc143c;
  cursor: pointer;
}
.search-icon {
  right: 15px;
}
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  height: fit-content;
  .criteria-dropdown {
    flex-basis: 35%;
  }
  .search-input {
    flex: 1;
    height: fit-content;
    position: relative;
  }
  @media (max-width: 868px) {
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .responsive-mobile-tablet-table {
    margin-top: 4px;
  }
  .card-body {
    background-color: white;
  }
}
</style>
